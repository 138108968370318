import React, { useState } from 'react';
import Layout from '../components/Layout';

import { Link } from 'gatsby';
import { SubscriptionForm } from '../components/SubscriptionForm';
import SEO from '../components/seo';
import { Typography, Paper } from '@material-ui/core';
import { graphql } from 'gatsby';
import { red } from '@material-ui/core/colors';
import pathToImageOfAnAppp from './timers-demo.png';

const Header = ({ children }) => (
  <Typography
    variant={'overline'}
    color={'textSecondary'}
    style={{ textTransform: 'uppercase' }}
  >
    {children}
  </Typography>
);

export default (props) => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props;
  return (
    <Layout location={location} title={title}>
      <SEO
        title={'FOCUTRON'}
        keywords={[`google assisstant`, `timers`, `menu bar`]}
      />
      <Typography variant={'h3'} color={'secondary'}>
        FOCUTRON (alpha)
      </Typography>
      <Typography variant={'headline'} color={'secondary'}>
        Displaying Google Assistant Timers in Menu Bar
      </Typography>
      <br />
      <br />
      <Header>SCREENSHOT</Header>

      <img
        style={{ margin: '0px', padding: '0px' }}
        src={pathToImageOfAnAppp}
      />

      <iframe
        width='100%'
        height='600'
        src='https://www.youtube.com/embed/rnh5zcm770Q'
        frameborder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      ></iframe>
      <br />
      <br />
      <Header>OVERVIEW</Header>
      <Typography paragraph variant={'headline'}>
        I write this app for myself to see timers I've set up by voice with by
        Google Assistant in Mac OS menu bar.
      </Typography>
      <br />
      <br />
      <Header>SUBSCRIBE</Header>
      <Typography paragraph variant={'headline'}>
        The app WILL update so subsribe to stay in touch.
      </Typography>
      <SubscriptionForm />
      <br />
      <br />
      <Header>SOURCE CODE</Header>
      <Typography paragraph variant={'headline'}>
        You have to build an app for your target platform.
      </Typography>
      <a
        style={{ color: red[500] }}
        href={'https://github.com/rohovdmytro/focutron'}
      >
        https://github.com/rohovdmytro/focutron
      </a>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
